import React from 'react'

export default function Home() {
  return (
    <div className = 'flex flex-auto bg-blue-gradient items-stretch max-w-full h-screen max-h-screen'>
      <div className = 'relative flex-auto max-w-full flex items-center justify-center'>
        <div className = 'flex flex-col text-center'>
          <h1 className = 'font-semibold text-4xl leading-14'>Pine Grove Customs</h1>
          <h2 className = 'font-thin text-2xl leading-14'>Coming soon...</h2>
        </div>
      </div>
    </div>
  )
}