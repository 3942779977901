import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Home from '@/pages/Home'

import '@/index.css'

export default function App() {
  return (
    <BrowserRouter>
      <div className = 'relative min-h-screen flex flex-col lg:flex-row'>
        <Switch>
          <Route exact path = '/' component = {Home} />
          <Route><Redirect to = '/'/></Route>
        </Switch>
      </div>
    </BrowserRouter>
  )
}